@import "../../App.scss";
.shift_group_row {
   
    .depot_bar{
      span{ border-radius:5px ; margin-left: 1px;
      &:first-child{ margin-left: 0px;}
      }
    }
    
    .depot_state .state{margin: 14px 0px;
     
      span.charging{ background: #FFF1D1 ;}
      span.available{ background: #DDE5E9;}
      span.offline{ background: #DDDDDD;}
      
    }
    
    span.charging{ background: #FFF1D1 !important;}
    span.available{ background: #DDE5E9 !important;}
    span.offline{ background: #DDDDDD !important;}
  } 