.station_route_map{ height: 50vh;
  margin-top: 13px; border-radius: 5px; overflow: hidden;
}




.station_icon_maps {
  width: 67.999px; height: 69px;  background: url(../../../assets//images//icons/vehicle_current_location_blank.svg) no-repeat center center;
  text-align: center;
  span{ color: #25556E; font-size: 11px;
    padding-top:25%;
    font-weight: 500; text-align: center; display: block; line-height: 22px;
  }
}