/*-------Base Theme Style Sheet---*/

/*-------Theme Color variable----*/
$theme: #65A844;
$theme-secondary: #25556E;
$text-color: #4D4D4F;
$placeholder-text-color: #343A40;
$placholder-color:#999999;
$white:#ffffff;
$body-bg:#F5F5F5;
$black:#000000;
$gray-text1:#cccccc;
$progress_bar_bg:#EEEEEE;
$gray-text-label:#666666;
$border-color:#E5E5E5;
$gray-btn:#DDE5E9;

/*-------State Color variable----*/
$available-bg:#DDE5E9;
$available-border:#25556E;
$connected-bg:#FFF1D1;
$connected-text:#8B6302;
$connected-border:#25556E;
$parked-bg:#E0EED9;
$parked-text:#3B771E;
$parked-border:#3A8814;
$transit-bg:#DDE5E9;
$transit-text:#074F74;
$transit-border:#25556E;
$reserved-bg:#DDE5E9;
$reserved-border:#25556E;
$charging-bg:#FFF1D1;
$charging-text:#8B6302;
$charging-border:#FDBB1D;
$complete-bg:#E0EED9;
$complete-text:#3B771E;
$complete-border:#65A844;
$error-bg:#FBE1D3;
$error-text:#E96A24;
$error-border:#E96A24;
$offline-bg:#DDDDDD;
$offline-text:#4D4D4F;
$offline-border:#888888;
$alert-marker:#FF0000;
$badge_color:#C90000;
$driver-available-bg:#DDE5E9;

$driver-onduty-bg:#E0EED9;
$driver-onduty-text:#3B771E;
$driver-not-available-bg:#EEEEEE;
$driver-not-available-text:#4D4D4F;
$driver-unavailable-bg:#888888;

$driver-suspended:#E96A24;


$connected-bg-dark:#999999;
$charging-bg-dark:#FDBB1D;
$complete-bg-dark:#65A844;


/*--------Font variable-----*/
$theme-font: 'Roboto', sans-serif !important;


/*--------Border variable-----*/
$divider: 1px solid #E5E5E5;
$theme-radius:5px;

/*--------Sort CSS-----*/
.img_res{ max-width: 100%; min-width: 100%;}
.text_right{text-align: right;}
.text_left{ text-align: left;}
.text_center{ text-align: center !important;}
.p_0{ padding: 0px !important;}
.p_tb_0{ padding-top: 0px !important; padding-bottom: 0px !important;}
.p_t_0{ padding-top: 0px !important; }
.p-b-0{ padding-bottom: 0px !important;}
.d-inline-b{ display: inline-block; vertical-align: top;}
.mt-3{ margin-top: 3px !important;}
.mt-13{ margin-top: 13px !important;}
.mt-16{ margin-top: 16px !important;}
.full_right{ float: right;}
.ml_0{ margin-left: 0px !important;}
.pl_pr_5{ padding-left: 5px !important; padding-right: 5px !important; }
.mt-0{ margin-top: 0px !important;}
.mb-10{ margin-bottom: 10px !important;}
.mt-5{ margin-top: 5px !important;}
.mt-10{ margin-top: 10px !important;}
.mt-30{ margin-top: 30px !important;}
.no-border{ border: none !important;}
.pt-10{ padding-top: 10px !important;}
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.w_100per{width: 100% !important;}
.min-w-i{ min-width: inherit !important;}
.d-none{ display: none !important;}
.mr-5{ margin-right: 5px !important;}
.d-in-b{ display: inline-block !important; vertical-align: middle;}
.pl_5{ padding-left: 5px !important;}
/*------Font Family Css End--------*/

/*-----------Main Css Start---------*/
html, body {font-family: 'Roboto', sans-serif !important; letter-spacing: inherit !important; line-height: inherit !important; padding:0px !important; font-size: 16px !important; margin: 0px !important; background: $body-bg !important; }
.back-arrow{ background-color: $white !important; width: 40px; height: 40px; border-radius:$theme-radius !important;
  .material-icons{ color: $theme-secondary; font-size: 16px;}
}

/*-----------Button Css Start---------*/
.button_row{ margin-top: 20px;
  .btn_row_text{ font-size: 14px; font-weight: 400; color:$text-color;
    .blue_bg{margin-left: 10px;}
  }
}

.csv_download{text-decoration: none;}
.btn_white{ background: $white !important; margin-left: 10px !important; border-radius : $theme-radius !important; padding: 0px 14px !important; font-size: 14px !important; font-weight: 400 !important; height: 40px !important; text-decoration: none !important; text-transform: capitalize !important; color: $theme-secondary !important ;
&.gry_bg{background: $gray-btn !important; color: $theme-secondary !important; margin-left: 10px ;}
&.light_gry{background:#eee !important; color: $theme-secondary !important; margin-left: 10px ;}
&.delet_acct{background:#A70404 !important; color: $white !important; margin-left: 10px ;}
&.small_btn{ font-size: 10px !important; height: 30px !important; 
.MuiButton-label{ height: 30px; line-height: 30px;}
}
&.blue_bg{background: $theme-secondary !important; color: $white !important; margin-left: 10px ;}
&.cancel{background: $progress_bar_bg !important; color: $theme-secondary !important;}
.MuiButton-label{ display: block; height: 40px; line-height: 43px;}
}

.outline_btn{ border: 1px solid #ccc !important; color: $theme-secondary !important; text-transform: capitalize !important;}

.text_btn_theme{ background: none !important;}
.text_btn{ font-size: 16px !important; font-weight:500 !important; color: $black !important; text-transform: capitalize !important; padding: 0px !important; margin: 0px 15px !important;
&.active{ color: $theme-secondary !important;}
}

.legend{ 
  .current{ margin-right: 5px; margin-top: -2px; border-radius: 50px; vertical-align: middle; background: $gray-text1; width: 10px; height: 10px; display: inline-block; border-radius: 50px;}
  .average{ margin-right: 5px; margin-top: -2px; border-radius: 50px; vertical-align: middle; background: $gray-text1; width: 10px; height: 10px; display: inline-block; border-radius: 50px;}
}

/*-----------Status Css Start---------*/
.evse_list .stat_list { margin-top: 10px;}
.stat_list { margin-top: 0px;}
.status{ font-size: 10px; margin-right: 5px; text-align: left; color: $text-color;  border-radius:3px; min-width:80px; height:28px; display: inline-block;
 span.status_text{ line-height: 28px; padding: 0px 7px;}
 span.status_number{ line-height: 28px; display: inline-block; float: right; padding: 0px 7px; height: 28px; min-width:28px; text-align: center; border-left:1px solid $white;}
  &.available{ background: $available-bg; 
    span.status_number{ }
  }
  &.connected{ background: $connected-bg !important; color: $connected-text !important;
    span.status_number{ }
    span.status_text{color: $connected-text !important;}
  }
  &.reserved{ background: $reserved-bg !important; 
    span.status_number{ }
    span.status_text{}
  }
  &.transit{ background: $transit-bg !important; color: $transit-text !important;
    span.status_number{ }
    span.status_text{color: $transit-text !important;}
  }
  &.parked{ background: $parked-bg !important; color: $parked-text !important; 
    span.status_number{ }
    span.status_text{ color: $parked-text !important; }
  }
  &.charging{ background: $charging-bg !important; color: $charging-text !important;
    span.status_number{ }
    span.status_text{color: $charging-text !important;}
  }
  &.complete{ background: $complete-bg !important; color: $complete-text !important;
    span.status_number{ }
    span.status_text{color: $complete-text !important;}
  }
  &.error{ background: $error-bg !important; color: $error-text !important;
    span.status_number{ }
    span.status_text{color: $error-text !important;}
  }
  &.in-service{ background: $error-bg !important; color: $error-text !important;
    span.status_number{ color: $text-color !important;}
    span.status_text{color: $text-color !important;}
  }
  &.offline{ background: $offline-bg; 
    span.status_number{ }
    span.status_text{}
  }
  &.driver_available{ background: $driver-available-bg !important; 
    span.status_number{ }
    span.status_text{}
  }
  &.driver_onduty{ background: $driver-onduty-bg !important; color: $driver-onduty-text !important;
    span.status_number{ }
    span.status_text{color: $driver-onduty-text !important;}
  }
  &.driver_suspended{ background: $error-bg !important; color: $error-text !important;
    span.status_number{ }
    span.status_text{color: $error-text !important;}
  }

  &.driver_not_available{ background: $driver-not-available-bg !important; color: $driver-not-available-text !important;
    span.status_number{ }
    span.status_text{color: $driver-not-available-text !important;}
  }
}
/*-----------Status Css End---------*/

main{padding:10px 30px 50px 30px !important; width: calc(100% - 200px);}
.section_title{ padding: 0px; margin: 0px 0px 10px 0px;
h2{ font-size: 20px; font-weight: 400; color: $black; margin: 0px; line-height: 20px;}
h3{ font-size:18px; font-weight: 400; color: $black; margin: 0px;}
.title_right h2{ font-size: 14px; text-align: right; color: $black; line-height: 20px;}
}

/*------Cards Css Start----*/
.map_card{ border: none  !important; background: none !important; border-radius: $theme-radius !important;}
.small_card{border: none  !important; background: $white !important; border-radius: $theme-radius !important; margin-bottom: 10px;
.card_content{ padding: 20px !important;}
}

.list_card{border: none  !important; width: 100%; background: $white !important; border-radius: $theme-radius !important; margin-bottom: 10px;
  .card_content{ padding: 20px 20px 17px 20px !important;}
}

.basic_card{border: none  !important; background: $white !important; border-radius: $theme-radius !important; margin-bottom: 10px;
  .card_content{ padding: 20px !important;}
  .MuiCardContent-root{ padding: 20px !important;}
}
/*------Cards Css End----*/

.mid_section_right{
  .analytics{ margin-top: 10px;}
  .energy_legend{ text-align: right;
    .legend_list{ font-size: 10px; color: $black; display: inline-block; margin-left: 15px;
      span{ width: 12px; height: 12px; display: inline-block; border-radius: 50px; background:#cccccc; margin-right: 7px;}
      span.average{ background: #eeeeee;}
    }
  }
}

.depot_bar{
  span{ display: inline-block; height: 20px; 
  &.charging{ background: #eeeeee;}  
  &.available{background: #e5e5e5;}
  &.offline{background: #cccccc;}
  &:first-child{ border-radius: 5px 0px 0px 5px;}
  &:last-child{ border-radius: 0px 5px 5px 0px;}
  }
}

.depot_state{
  
  .state{ text-align: center; font-weight: 400; margin: 14px 15px 0px 15px; line-height:12px}
  span{ width: 60px; height: 5px; border-radius: $theme-radius; display: inline-block;
    &.charging{ background: #eeeeee;}  
    &.available{background: #e5e5e5;}
    &.offline{background: #cccccc;}
  }
  .state_text{ font-size: 10px; text-transform: uppercase; color: $black;}
  .state_number{ font-size: 26px; font-weight: 400; color: $black; line-height: 32px;}
}

.depot_list{
  ul{ padding: 0px;
    li{ padding: 0px; cursor: pointer;
    .depot_graph{ height: 70px;}
    }
  }
}

.depot_details{
  .depot_information{ display: inline-block; vertical-align: top;
    .depot_location_map{ overflow: hidden; border: 1px solid $gray-text1; margin-right:10px; border-radius: $theme-radius; width:70px; height: 70px; display: inline-block; vertical-align: top;}
    .depot_info{ display: inline-block; vertical-align: top;
      .depot_name{ font-size: 18px; font-weight:400; color: $black; line-height: 22px; margin-bottom: 3px;
      span{ color:$offline-border;}
      }
      .depot_addr{font-size:14px; font-weight:400; color: $text-color; line-height: 17px; margin-bottom: 10px; }
      .port_details{font-size:12px; font-weight:400; color: $text-color; line-height: 14px;
      span{ margin-right: 40px;
        img{ margin-right: 5px; display: inline-block; vertical-align: middle; margin-top: -2px;}
      }
      }
    } 
  }    
}

.depot_detail_head{
  .stat_list{ float: right; text-align: right; background: #f5f5f5;}
  .status{margin-right: 0px; margin-left:5px;}
}

.detail_page_tab{ margin-top: 10px;
  .defult_tab{ box-shadow: none;
  .tab_nav{ border-bottom: 1px solid #ccc; padding: 0px 10px;
    .tab_nav_item{ font-size: 14px; opacity: 1; margin-right: 30px; font-weight: 400; text-transform: capitalize; min-width: inherit; padding: 13px 10px; color: $text-color;
    &.Mui-selected{ font-weight: 500; color: $theme-secondary;}
    .alert_badge .MuiBadge-badge{ background: $badge_color; right: 9px; top:12px; border-radius: 3px; height: 18px; min-width: 18px;}
    }
    .MuiTabs-indicator{ background-color: $theme-secondary; height: 5px;}
  }
  
  }
  .tab_detail{ padding-top:23px;}
}

.soc_level{ width: 100%;
.text_class{ display: inline-block; vertical-align: middle; margin-right: 12px; min-width: 29px;}
}
.stat_progress{ width:120px; display: inline-block; background: $progress_bar_bg; height: 10px;
.bar{ display: block; height: 10px; line-height: 10px; position: relative;
  // &:after{ content: ""; width: 8px; height: 4px; top:-4px; left: 100px; position: absolute; background: url(../src/assets/images/icons/ic_arrow_drop_down_24px.svg) no-repeat;}
  &.connected{ background: $connected-bg-dark;}
  &.charging{ background: #FDBB1D}
  &.complete{ background: $complete-bg-dark;}
  &.est{background: $charging-bg-dark;}
  &.battery_full{ background: #65A844}
  &.battery_avg{ background: #FDBB1D}
  &.battery_low{ background: #E96A24}
 }
}

.position_fixed_head{ background:#F5F5F5 !important; position: sticky; position: -webkit-sticky; top: 80px; z-index: 999;}
.position_fixed_nav{ background:#F5F5F5 !important; position: sticky !important; position: -webkit-sticky; top:163px; z-index: 999;}

.scroll_effect .position_fixed_nav{ top:108px; transition: all 0.3s ease-in-out;}
.scroll_effect .depot_information{ height: 80px; transition: all 0.8s ease-in-out;}
.scroll_effect .depot_location_map { opacity: 0; height: 0px !important; width:0px !important; transition: all 0.8s ease-in-out;}
.scroll_effect .depot_addr{ opacity: 0; height: 0px !important; transition: all 0.8s ease-in-out;}

.alert_unread_mark span{ margin-right: 14px; width: 10px; height: 10px; display: inline-block;  border-radius: 50px;
&.unread{background: $alert-marker;}
}

/*-------------Information Tab-------------------*/
.information_section{ margin-top: 30px;
  .title_row{
    h2{ font-size: 16px; float: left; font-weight: 500; color:$theme-secondary; text-transform: uppercase; margin: 0px;}
    .action_icon{ background:#eee; border-radius: 3px; height: 28px; width: 28px; float: right; text-align: center;
      .top_action_btn{ width: 28px; height: 28px;}
    }
  }
  .info_detail{
    .tb_info_section{ margin-top: 20px;
      .label{ font-size: 10px; font-weight:400; line-height: 13px; color: $gray-text-label;}
      .result{ font-size: 14px; font-weight: 400; color: $black; line-height: 17px;
      .label{ vertical-align: top; line-height: 24px;   font-weight: 500; color: $black; font-size: 14px; width:95px; padding-right: 10px; display: inline-block;}
      .result{ display: inline-block; vertical-align: top; width:calc(100% - 140px); line-height: 24px; }
     
    }
    }
    
  }
  .zone_section{margin-top: 20px;
    .zone_detail{
      .zone_map{ border: 1px solid #999; border-radius: $theme-radius; margin-right: 20px;}

    }
    .zone_floor_info{
      .z_floor_list{ padding: 0px;
      .MuiListSubheader-root{ padding: 0px; line-height: inherit;}
      .list_head{ font-size: 16px; font-weight:500; color: $theme-secondary; line-height: 19px; margin-bottom: 7px; }
      li{ padding: 0px; font-size: 14px; font-weight: 400; color: $theme-secondary; line-height: 17px; margin-bottom: 7px;
        .circle{ width: 16px; height: 16px; border: 1px solid #707070; border-radius: 50px; line-height: 15px; margin-right: 5px; font-size: 10px; text-align: center; color: $text-color;}
        span{ font-size: 14px; font-weight: 400; color: $theme-secondary; line-height: 17px;}
      .MuiListItemText-root{ margin: 0px;}
      }
      }
      
    }
    .tb_zone_section{
      .label{ font-size: 10px; font-weight:400; line-height: 13px; color: $gray-text-label; margin-bottom: 10px;}
      .result{ font-size: 14px; font-weight: 400; color: $black; line-height: 17px; margin-bottom: 10px; color: $theme-secondary;
      .img_div{ display: inline-block; text-align: center; min-width: 20px; margin-right: 10px; vertical-align: middle;}
      }
      &.amenities{
        .result{ color: $black;}
      }
    }
  }
}
.clearfix{ clear: both;}

.dialog_container{
  &.dialog_container_center{
    .MuiDialog-container{ align-items:center;}
    .MuiPaper-rounded{ border-radius: 5px;}
    .MuiDialog-paperWidthSm{ min-width: 1115px; max-width:1115px; max-height: 98vh;}
  }
  &.set_fee_dialog{
    .MuiDialog-paperWidthSm{ min-width: 725px; max-width:725px; max-height: 98vh;}
  }
  .MuiDialog-container{ align-items:center;}
  .MuiDialog-paperWidthSm{ min-width: 840px; max-width: 840px; max-height: 98vh;}
  .MuiDialog-paper{ margin-bottom: 0px;}
  .MuiPaper-rounded{ border-radius: 5px 5px 0px 0px;}
  .dialog_title{ padding: 20px 30px 14px 30px; 
    
  h4{ margin: 0px; font-size: 20px; font-weight: 500; color: $black; display: inline-block;}
  .closed_btn{ padding: 0px; width: 20px; height: 20px;
    .material-icons{ color:rgba(0, 0, 0, 0.5)}
  }
  }
  .history_charging_list{ padding: 30px; border-top: 1px solid #DDDDDD;
  .h_charge_list{ font-size: 14px;
    .label{ display: inline-block; vertical-align: top; font-weight: 500; line-height: 24px; color: $black; width: 38%;}
    .result{display: inline-block; vertical-align: top; font-weight: 400; width: 58%; line-height: 24px;
     
    a{ color: $theme-secondary; font-weight: 500;}
    }
  }
  }
  .scroll_content{ max-height: 100vh; overflow-y: auto;}
}

.dialog_container{
  .charge_rate_set{
    .label{ width:165px !important; font-size: 14px; font-weight: 500;}
    .result{ width: 220px !important; text-align: left !important;
      
      .form_control .MuiSelect-select{ width: 200px !important; font-size: 12px !important; background-position: 195px center !important;}
    }
  }
  .charge_set_tab{
    .label{ width:165px !important; font-size: 18px !important; font-weight: 500 !important;}
    .result{ width: 440px !important; text-align: left !important;
      
      .MuiAppBar-positionStatic{ box-shadow: none; height: 42px; border: 1px solid #ccc; border-radius: $theme-radius; background: $white;}
      .MuiTab-textColorInherit.Mui-selected{ background: #F5F5F5;}
      .MuiTabs-indicator{ display:none;}
      .MuiTab-root{ max-width: 50%; min-height: 40px; min-width: 50%; font-size: 14px; font-weight: 400; opacity: 1; color:$theme-secondary; text-transform: capitalize;}
      .MuiTab-root:first-child{ border-right: 1px solid #ccc; border-radius: 5px 0px 0px 5px;}
      .MuiTab-root:last-child{border-radius: 0px 5px 5px 0px;}
    }
  }

  h5{ font-size:16px; font-weight: 500; color:$black; text-align: center; margin:0px 0px 15px 0px;}
  .defult_table{ 
  
  .MuiDataGrid-row{background: $progress_bar_bg;}
  .MuiDataGrid-root .MuiDataGrid-cell{ border-bottom: 1px solid #fff;}
  .MuiDataGrid-root .MuiDataGrid-cell:first-child{ border-bottom:none;}
  .MuiDataGrid-root .MuiDataGrid-cell:last-child{ border-bottom:none;}

  .table .MuiDataGrid-columnHeaderWrapper{ color: $text-color;}
  .table .MuiDataGrid-window{ color: $text-color;}

  .MuiDataGrid-row:hover{background: $white;}
  .MuiDataGrid-row:hover .MuiDataGrid-cell{ color:$theme-secondary !important;}

  }
   .charging_fee{
    h5{ text-align: left; margin-bottom: 0px;}
    .member_fee{ margin-top: 30px;
      .label{ width: 100%;}
      .result{width: 340px; text-align: left;}
    }
    .push_inside{ padding:5px 8px;}
  }
.list_form{ margin-top:16px;

  .billing_detail_table{
    .label {width:calc(100% - 245px); margin-bottom: 0px; font-size: 12px;}
    .result{width:245px; margin-bottom: 0px;
      .table_title{ font-size: 11px; width: 60px; color: $black; text-align: center;}
      fieldset{ border-bottom:none; border-left: none;}
    .MuiGrid-item { text-align: left;}
    .form_control .MuiOutlinedInput-root{ border-radius: 0px;}
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{  border-bottom:none !important; border-left: none !important;}
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{  border-bottom:none !important; border-left: none !important;}
    }
    .result .MuiGrid-container .MuiGrid-root:first-child fieldset{ border-left: 1px solid $gray-text1 !important;}
    .list_row:last-child fieldset{ border-bottom: 1px solid $gray-text1 !important;}
    .list_row:nth-child(2)  .result .MuiGrid-container .MuiGrid-root:first-child fieldset{ border-radius: 5px 0px 0px 0px;}
    .list_row:nth-child(2) .result .MuiGrid-container .MuiGrid-root:last-child fieldset{ border-radius: 0px 5px 0px 0px;}
    .list_row:last-child  .result .MuiGrid-container .MuiGrid-root:first-child fieldset{ border-radius: 0px 0px 0px 5px;}
    .list_row:last-child  .result .MuiGrid-container .MuiGrid-root:last-child fieldset{ border-radius: 0px 0px 5px 0px;  border-bottom: 1px solid $gray-text1 !important;}
    .list_row:last-child  .result .MuiGrid-container .MuiGrid-root fieldset{  border-bottom: 1px solid $gray-text1 !important;}
    .result .input_text{  text-align: right; font-size: 12px; font-weight: 400; color: #DDDDDD; width:60px !important; height: 40px; padding: 10px 20px 10px 10px;}
  }

  .label{ margin-bottom: 10px; font-size: 14px; color: $black; line-height:16px; display: inline-block; vertical-align: middle; width:165px; text-align: left;
    span{ font-size: 10px; color:#888888; display: block;}
  }
  .label{
    span.top_text{ font-size: 12px; color:$black; display: block;}
  }

  .result{margin-bottom: 10px; display: inline-block; vertical-align: middle; text-align: right; width:77px ;
    &.text_label{ font-size: 12px; font-weight: 400; color: $text-color; height: 40px; line-height: 40px; text-align: center;
    span{ font-weight: 500; margin-right: 13px;}
    } 
    .form_control{
      &.gra_period .input_text{ text-align: left; padding: 10px 10px 10px 10px;
        .MuiInputBase-input{ text-align:left;}
      }
      .MuiOutlinedInput-root{ border-radius: $theme-radius; background: $white;}
      .MuiOutlinedInput-root.disable{ background: $body-bg;}
      .MuiSelect-selectMenu{  text-align: left; font-size: 12px; font-weight: 400; color: $text-color;}
      .MuiSelect-select{ background: url(./assets/images/icons/ic_expand_more.svg) no-repeat 60px center; border: none; width:60px; height: 20px; padding: 10px; border-radius: $theme-radius; font-size: 12px; font-weight: 400; color: $text-color;}
      .MuiSelect-icon{ display: none;}
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{  border: 1px solid $gray-text1;}
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{ border: 1px solid $gray-text1;}
      
      .input_text{  text-align: right; font-size: 12px; font-weight: 400; color: #DDDDDD; width:80px; height: 40px; padding: 10px 20px 10px 10px;
      .MuiInputBase-input{ text-align: right; padding: 0px;}
      .MuiTypography-root{font-size: 12px; color:#999}
      }
    
    }
   
  }
}
.table_footer .btn_white.blue_bg{ margin-left: 0px !important;}
}

/*--------zone detail pop up--------*/
.station_zone{
  .zone_map{ border: 1px solid $border-color; border-radius: $theme-radius;}
  .zone_floor_info{
    .z_floor_list{ padding: 0px;
    .MuiListSubheader-root{ padding: 0px; line-height: inherit;}
    .list_head{ font-size:14px; position: inherit; font-weight:500; color:$text-color; line-height: 17px; margin-top: 20px; margin-bottom:10px;
      .circle{ width: 16px; height: 16px; border: 1px solid #707070; border-radius: 50px; line-height: 15px; margin-right: 5px; font-size: 10px; text-align: center; color: $text-color; display: inline-block;}
    }
    li.MuiListItem-root{ padding: 0px 0px 0px 26px;  margin-bottom: 10px;
      .icon{ min-width: inherit; margin-right: 10px;}
      span{ font-size: 14px; font-weight: 400; color: $theme-secondary; line-height: 17px;}
    .MuiListItemText-root{ margin: 0px;}
    }
    }
    
  }
}

.analytics{
  .info_detail{
    p{ font-size: 14px; margin: 0px; line-height: 17px; margin-top: 10px; color: $text-color;}
  }
  .button_row{
    .btn_white.blue_bg{ margin-left: 0px !important;}
  }
  .analytics_graph{ margin: 20px 0px;}
  .legend{ font-size: 10px; font-weight: 400; color: $black; display: inline-block; margin-right: 15px;
    .marker{ width: 10px; height: 10px; display: inline-block; margin-right: 5px; margin-top: -2px; border-radius: 50px; vertical-align: middle; border: 1px solid $theme; background: rgba(101, 168, 68, 0.2);}
    .peak{ color: $theme-secondary; font-weight: 500; margin-left: 20px; margin-right: 5px; display: inline-block;}
  }
}

.station_stats{
  .stn_list{ padding-right:0px; width: 16% !important;
    .stats_title{ font-size: 12px; line-height: 14px; text-transform: uppercase;  font-weight: 500; color: $text-color; margin-bottom: 10px;}
    .stats_port{ margin-bottom: 5px;
      .stats_port_name{ font-size: 8px; line-height: 14px; font-weight: 400; color: $text-color;}
      .stats_port_bar{ width:90%; height: 10px; background: $progress_bar_bg; border-radius: $theme-radius;
      span{height: 10px;  border-radius: $theme-radius; background: $gray-text1; display: inline-block;}
      }
    }
  }
}

.cal_btn{ float: right;
  .select_days{ display: inline-block; vertical-align: middle;  border-radius:$theme-radius; background: $progress_bar_bg; border: none;
  .day_select_box{ min-width: 100px;
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{ border: none;}
    .MuiOutlinedInput-notchedOutline{ border: none;}
    .MuiSelect-icon{ color: $theme-secondary;}
    .MuiSelect-select:focus{background: $progress_bar_bg; border-radius:$theme-radius;}
  .MuiSelect-select{ min-height: 32px; padding-top: 0px; line-height: 34px; padding-bottom: 0px; font-size: 12px; color: $theme-secondary;}
  }
  }
  .choose_date{ display: inline-block; vertical-align: middle; margin-left: 10px; font-size: 12px; color: $theme-secondary;
    .MuiButtonGroup-contained{ box-shadow: none; border-radius: 5px;}
    .MuiButton-contained:hover{ box-shadow: none; }
    .MuiButton-contained{background: $progress_bar_bg;}
    .MuiButtonGroup-groupedContainedHorizontal:not(:last-child){ border-right: 1px solid #fff;}
    .MuiSvgIcon-root{ color: $theme-secondary; }
    .MuiButton-label{font-size: 12px; font-weight: 400; color: $theme-secondary; line-height: 12px;} 
  }
}

.daily_select_option{ font-size: 12px; padding: 5px 10px; cursor: pointer;}

.station_details{
  .detail_page_tab .defult_tab .tab_nav{ padding: 0px 0px 0px 10px;}
  .information_section .info_detail .tb_info_section .row{  font-weight: 400; }
  .information_section .info_detail .tb_info_section .row .label{width:190px; vertical-align: top; font-weight: 500; line-height: 24px; font-size: 14px; color: $black; display: inline-block;}
  .information_section .info_detail .tb_info_section .row .result{ width:calc(100% - 190px); vertical-align: top; font-weight: 400; line-height: 24px; font-size: 14px; color: $black; display: inline-block; word-wrap: break-word;}
  .map_section{width:100%; margin-top: 20px; border-radius: $theme-radius;}
  .depot_information .depot_location_map{ background: $white;}
  .port_card{
    .MuiCardContent-root{ padding: 0px !important;}
    
    .title_row{ background: #e5e5e5; padding: 16px 20px;
      h2{ margin-top: 3px;}
      .status{ text-align: center; margin-right: 0px;}
    }
    .port_details{ padding: 16px 20px;
      &.tb_info_section{margin-top: 0px;}
      .row{
        .label{ width: 150px !important;}
        .result{ width:calc(100% - 150px) !important;}
      }  
      .blue_bg{ margin-left: 0px !important;}
     
    }

  }
}

.zone_card{
  .row{
    .label{ width: 150px !important;}
    .result{ width:calc(100% - 150px) !important;}
  } 
} 

.station_details .port_card .vehicle_info_card{
  .row{
    .label{ width:180px !important;}
    .result{ width:calc(100% - 180px) !important;
    .select_control{ color: $theme-secondary !important; font-weight: 500; width: 180px !important; max-width: 100%; min-width: 100px !important;
    .MuiSelect-select{color: $theme-secondary !important; font-weight: 500;}
    }
    }
  } 
} 

.tab_menu_select{ position: absolute; right: 0px; z-index: 999;
  .tab_menu_select_control{ margin-right: 0px;
    .MuiInputBase-root{ width: 180px;}
    .MuiSelect-select{ background:$white url(./assets/images/icons/ic_select_caret.svg) no-repeat 155px center; border:1px solid #e5e5e5; width: 180px; height:28px; line-height: 30px; border-radius: $theme-radius; font-size: 12px; font-weight: 500; color: $theme-secondary; padding: 6px 24px 6px 12px;}
    .MuiInput-underline:before{ border-bottom: none !important;}
    .Mui-focused.MuiInput-underline:after{ border-bottom: none !important;}
    .MuiSelect-icon{ display: none;}
  }
}

.vehicle_detail{
  .depot_information .depot_location_map{ background-color: $white;} 
}
.vehicle_list{
  .depot_details .depot_information .depot_info .port_details span{ margin-right: 0px; display: block; margin-top: 8px;
  span{ min-width: 80px; display: inline-block !important; margin: 0px; min-width:25px;}
  }
  .depot_list ul li .depot_graph{ height: 70px;}
  .depot_details .stat_list{ margin-top: 10px;}
}
.image_gallery{ margin-top: 13px;
.image-gallery-slide-wrapper{ background-color: $black; padding: 30px 0px;}
.image-gallery-left-nav{ background: url(../src/assets/images/icons/icon-prev.svg) no-repeat; filter:none; width: 50px; height: 50px; padding: 26px; left: 20px;}
.image-gallery-right-nav{ background: url(../src/assets/images/icons/icon-next.svg) no-repeat; filter:none; width: 50px; height: 50px; padding: 26px; right: 20px;}
.image-gallery-left-nav svg{ display: none;}
.image-gallery-right-nav svg{ display: none;}
.image-gallery-bullets{ bottom: 10px; }
.image-gallery-bullets .image-gallery-bullet{padding: 3px;}
.image-gallery-slide .image-gallery-description{ font-size: 16px; font-weight: 400; bottom: 10px;}
.image-gallery-fullscreen-button{ bottom:25px}
.image-gallery-thumbnail{ width: 100px; height: 100px; border-radius: 5px; }
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{ border: 3px solid #65A844; }
.image-gallery-thumbnail.active:hover{ border: 3px solid #65A844; }
.image-gallery-thumbnail.active .image-gallery-thumbnail-image, .image-gallery-thumbnail.focus .image-gallery-thumbnail-image{ border-radius:3px; width:calc(100% - 2px); height: 92px;  }
.image-gallery-thumbnail .image-gallery-thumbnail-image{ height: 92px; width:calc(100% - 2px); border-radius: 3px;}
.image-gallery-content .image-gallery-slide .image-gallery-image{ width: 100%; }
.image-gallery-content .image-gallery-slide .image-gallery-image{ min-height: 305px; max-height: calc(100vh - 170px);}
.image-gallery-thumbnail:hover{ border: 3px solid #fff;}
.image-gallery-thumbnail{ border: 3px solid #fff;}
}

.depot_details .depot_information .depot_info .depot_name .status { margin-left: 20px; margin-top: 0px; vertical-align: middle;}

.soc_value{ margin-top: 18px; font-size: 14px;
  .soc_col{ display: inline-block; color: $gray-text-label; margin-right: 10px; min-width: 80px;
  .label{ font-weight: 500; color: $black; margin-right: 10px;}
  }
}
.charge_fee_cal{ padding: 17px 20px; text-align: right; position: relative;
  .cal_legend{position: absolute; top: -50px; text-align: right; right: 20px;  display: inline-block;
      .legend{ min-width: 80px; height: 28px; text-align: center; margin: 0px 0px 0px 10px; padding:5px 10px; border-radius:5px; background:#eee; border: 1px solid #eee; display: inline-block; vertical-align: top; font-size: 12px; font-weight: 400; color: $text-color;
      &.off_peak{ background: rgba(0, 185, 255, 0.2); border: 1px solid #00B9FF;}
      &.peak{ background: rgba(255, 72, 156, 0.2); border: 1px solid #FF489C;}
      &.super_peak{ background: rgba(195, 101, 253, 0.2); border: 1px solid #C365FD;}
      }
  }

  .rbc-time-view{ border:none;}
  .rbc-time-header.rbc-overflowing{ border-right: none;}
  .rbc-timeslot-group{ border-bottom:1px solid #fff;}
  .rbc-time-content{ border:none;}
  .rbc-header{ border:none;}
  .rbc-day-bg + .rbc-day-bg{ border:none;}
  .rbc-time-content > * + * > *{ border-right: none; border-left: none;}
  .rbc-time-header-content{border-left: none; margin-bottom: 10px; margin-top: 10px;}
  .rbc-header{ font-size: 14px;  color: $text-color; font-weight: 500; text-transform: uppercase; margin-right: 10px;}
  .rbc-time-slot{ font-size:12px; color: $text-color; font-weight:400; text-transform: uppercase;}
  .rbc-day-slot .rbc-events-container{ margin-right: 0px;}
  .rbc-today{ background:none;}
  .rbc-time-content > .rbc-day-slot{ background: #EEEEEE; border-radius:8px ; width: calc(100% - 10px); margin-right: 10px;}
  .rbc-day-slot .rbc-time-slot{ border-top: 1px solid #fff;}
  .rbc-toolbar{ display: none;}
  .rbc-allday-cell{ display: none;}
  .rbc-event-label{ font-weight: 500; text-align: center; width: 100%; font-size: 100%;}
  .rbc-day-slot .rbc-time-slot {
    border: unset;
  }
  .rbc-current-time-indicator{ display:none;}
}

.set_fee_dialog{
  .dialog_title{ background: $body-bg; padding: 13px 30px;
  h4{ margin-top: 10px;}
  }

  .separator{ background:#e5e5e5; height: 1px; margin: 15px 0px 15px 0px;}  
}

.charge_fee_btn{ float: left;}

.schedule_select{ display: inline-block; vertical-align: top; margin-right: 10px;
  .select_control{background: url(./assets/images/icons/ic_date_range.svg) no-repeat 15px center; width: 162px;  padding: 0px 0px !important; height: 40px; border-radius: $theme-radius; border: 1px solid #E5E5E5 !important;
    .MuiInputBase-root{ width: 149px;}
    .MuiSelect-select{ background: url(./assets/images/icons/ic_select_caret.svg) no-repeat 125px center; width: 149px; height:28px; line-height: 30px; border-radius: $theme-radius; font-size: 12px; font-weight: 400; color: $theme-secondary; padding: 6px 24px 6px 40px;}
    .MuiInput-underline:before{ border-bottom: none !important;}
    .Mui-focused.MuiInput-underline:after{ border-bottom: none !important;}
    .MuiSelect-icon{ display: none;}
  }
}

.cal_card_state{
  .MuiCardContent-root{ padding: 0px !important;
  .title_row{ border-bottom: 2px solid $body-bg; padding: 15px 20px;
  .outline_btn{ font-size: 12px; font-weight: 400; height: 40px;
  img{ margin-right: 8px;}
  }
  }

}

}
.time_zone_info{ font-size: 14px; font-weight:500; color: $gray-text-label;
span{ font-weight: 400;}
}

.edit_holiday{ text-align: left;
  .label_input{
    .form_control{margin-bottom: 17px;
      .MuiInputBase-input{ padding: 5px 10px; ; font-size: 12px; min-height: 28px; font-size: 12px; font-weight: 400; border: 1px solid #ccc; border-radius: $theme-radius;}
      .date{ background: url(./assets/images/icons/ic_date_range.svg) no-repeat 90% center;}
      .MuiOutlinedInput-notchedOutline{ border: none;}
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{ border: none;}
    }
  }
  h4{ margin: 0px 0px 20px 0px; font-size: 20px; color: $black; font-weight: 400;}
  h5{margin: 0px 0px 20px 0px; font-size: 14px; color: $text-color; font-weight: 500;}
  .setting_state .status_list{ min-height: 40px; margin-bottom: 17px;}
  .row_list{ font-size: 12px; line-height: 32px;
  &.holiday{ color: $black;}  
  &.status_list{font-size: 12px; text-decoration: underline; color:$theme-secondary; cursor: pointer;
  &:hover{ text-decoration: none;}
  }
  &.date{ }
  }
}
.map_action_row{ margin-bottom: 10px; margin-top: -20px;
.vehicle_search{}
}

.map_legend{ display: inline-block; margin-left:2px; background: $white; font-size: 14px; font-weight: 400; color: $black; padding: 6px 15px;
img{ display: inline-block; vertical-align: middle; margin-right:8px; margin-top: -3px;}
.MuiCheckbox-root{ padding: 0px; margin-right: 10px; margin-top: -2px; }
.MuiCheckbox-colorPrimary.Mui-checked{ color: $theme-secondary;}
.MuiCheckbox-root{ color: $theme-secondary;}
}

.vehicle_location{
 .depot_information .depot_info .depot_name{ font-size: 22px; font-weight: 500;}
 .stat_progress .bar:after{ display: none;}
 .soc_value .soc_col .result{ display: inline-block;}
 .soc_level .text_class{ color: $black;}

}
.select_control{ min-width: 100% !important; padding: 0px 10px !important; height: 34px; border-radius: $theme-radius; border: 1px solid #E5E5E5 !important;
  .MuiSelect-select{font-size: 12px !important; color: $text-color !important; line-height: 22px; background: url(./assets/images/icons/ic_select_caret.svg) no-repeat 100% center !important; }
  .MuiInput-underline:before{ border: none !important;}
  .MuiSelect-icon{ display: none;}
  .MuiSelect-select:focus{ background: none;}
  .MuiMenuItem-root{font-size: 12px !important;}
}

.select_location .select_control{min-width:220px !important; background: #fff url(./assets/images/icons/ic_directions.svg) no-repeat 10px center !important; border: none !important; 
.low_font{ margin-left: 5px; color: #999999;}
}

.optionlist_dest{ font-size: 12px !important; 
  .low_font{ margin-left: 5px; color: #999999;}
  }
.select_location .select_control .MuiSelect-select{ padding-left: 30px;}
.route_map{ height: 100vh;}
.license_img{ margin-top: 10px;}
.information_section .info_detail.licence_info .tb_info_section .result .label{ width: 120px;}
.dialog_container .trip_details .h_charge_list .label{ width: 48%;} 
.dialog_container .trip_details .h_charge_list .result{ width: 50%;} 

.driver_schedule{ margin-top: 8px;
  .label{ font-size: 14px; font-weight: 500; color: $black; text-align: left; line-height: 38px;}
  .select_control{ min-width: 90% !important; text-align: left; height: 38px;}
  .driver_schedule_list{ margin-top: 17px;}
  .driver_schedule_list:first-child{ margin-top: 0px;}
  .driver_btn{ width: 90%; padding: 0px !important; font-size: 13px !important;}
  .error_msg{ color: #A70404; font-size: 14px; margin-top: 20px; font-weight: 400; }
}
.set_schedule{
  .section_row{ padding:10px;}
  .select_control{ height: 40px;
    .MuiSelect-select{ min-height: 25px; line-height: 27px;}
  }
  h5{ font-size: 18px;}
  label{ font-size: 14px; color: $black; font-weight: 500; vertical-align: middle; line-height: 14px;}
  .set_consumption{ margin-top: 5px;
    .priority_label{ font-size: 14px; font-weight: 400; color: $text-color; margin-bottom: 15px;
      span{ display: inline-block; width: 15px; margin-right: 10px; margin-top:-3px; height: 15px; border-radius:3px; vertical-align: middle;
      &.priority_1{ background-color:#FDBB1D;}
      &.priority_2{ background-color:#00A8FF;}
      &.priority_3{ background-color:#C365FD;}
      &.others{ background-color:#5DD622;}
      }
    }
    .add-remove_circle{text-align: right; 
        a{ margin-left: 20px; display: inline-block; width: 20px; height: 20px; margin-bottom: 15px;}
    }
  }
}
.station_details .information_section .info_detail .tb_info_section .row.set_priority .label{ width: 148px;}
.station_details .information_section .info_detail .tb_info_section .row.set_priority .result{
  width: calc(100% - 148px);
  .select_control{ min-width: calc(100% - 60px) !important; height: 40px; vertical-align: middle;
    .MuiSelect-select{ font-weight: 500; color: $theme-secondary !important; line-height: 30px;}
  }
  .btn_white.blue_bg{ min-width: inherit;}
}

.des_numb_icon_maps {
  width: 22px; height: 26px; background: url(./assets/images/icons/ic_beenhere.svg) no-repeat center center;
  color: white; text-align: center;
    span{ color: $white; font-size: 12px; font-weight: 500; text-align: center; display: block; line-height: 22px;}
}

.vehicle_icon_maps {
  width: 67.999px; height: 69px;  background: url(./assets/images/icons/vehicle_current_location.svg) no-repeat center center;
  text-align: center;
  span{ color: $theme-secondary; font-size: 11px;
    padding-top:25%;
    font-weight: 500; text-align: center; display: block; line-height: 22px;
  }
}

.vehicle_icon_dynamic_maps {
  width: 67.999px; height: 69px;  background: url(./assets/images/icons/vehicle_current_location_blank.svg) no-repeat center center;
  text-align: center;
  span{ color: $theme-secondary; font-size: 11px;
    padding-top:2px;
    font-weight: 500; text-align: center; display: block; line-height: 22px;
  }
}

.view_stop{ display: inline-block; margin-right: 10px; min-width: 150px; vertical-align: middle;
  .select_control{ background: #fff; text-align: left; height: 37px;}
}

.msg_badge{
  .MuiBadge-badge{ background: #C90000; font-size: 10px; right: 0px; top: 13px; border-radius: 3px; height: 20px;  min-width: 18px;}
}

.vehicle_analytics{
  .btn_white.blue_bg{ margin-left: 0px !important;}
  .title_row{ margin-bottom: 25px;
    h4{ font-size: 18px; font-weight: 500; color: $black; line-height: 24px; margin: 0px;}
    .sub_heading{ font-size: 12px; font-weight: 400; color: $black;}
  }
  .graph_capt{ text-align: left; font-size: 14px; font-weight: 500; line-height: 24px; color: $black;}
  .card_legend{ margin-top: 20px; padding-top: 10px; border-top: 1px solid #CCCCCC; 
  .legend_item{font-size: 11px; font-weight: 400; margin-bottom: 2px;
    img{ margin-right: 5px;}
  &.available{ color:#25556E;}
  &.transit{ color:#25556E;}
  &.parked{ color:#3A8814;}
  &.charging{ color: #E0A006;}
  &.in-service{ color:#E96A24;}
  &.complete{ color:#3A8814;}
  &.connected{ color:#E0A006;}
  &.offline{ color:#888888;}
  &.error{ color: #E96A24 ;}
  &.reserved{ color: #25556E ;}
   
  }
  }

  .efficiency_bar{
    &.five_day_status{
      .bar{ background: none !important;
      span{height: 10px; border-radius: 5px; display: block; margin-left: 1px;
      &:first-child{ margin-left: 0px;}
      &.transit{ background: #25556E !important;}
      &.parked{ background: #3A8814 !important;}
      &.charging{ background: #3A8814 !important;}
      &.in-service{ background: #FDBB1D !important;}
      &.complete{ background: #FDBB1D !important;}
      &.connected{ background: #E96A24 !important;}
      &.offline{ background: #888888 !important;}
      
      }
      }
    }
    .MuiList-root{ padding: 0px;
      .MuiListItem-root{ margin-top: 20px; padding: 0px; font-size: 12px; font-weight: 500; color: $black; display: block;
        &:first-child{ margin-top:0px;}
      .date{ text-align: left;}
      .time{ text-align: right;}
      .bar{background: rgb(101,168,68); height: 10px; border-radius: 5px; display: block; margin-top: 5px;
        background: linear-gradient(90deg, rgba(101,168,68,1) 0%, rgba(224,160,6,1) 50%, rgba(235,12,12,1) 100%);
        span{ position: relative; margin-top: 1px; width: 8px; height: 8px; display: inline-block; background: $white; border-radius: 5px;}
      }
      }
    }
  }
  .energy_detail{
    .MuiList-root .MuiListItem-root{ margin-top: 10px;}
    .port{ display: inline-block; vertical-align: middle; font-size: 12px; font-weight: 500; color: $black; width:63px;}
    .proress_bar{ display: inline-block; vertical-align: middle; position: relative;  width: calc(100% - 145px); height: 10px; border-radius: 3px; background: $border-color;
    span{ display: inline-block; height: 10px; background: #A6C38D; position: absolute; left: 0px; top:0px; border-radius: 3px;}
    }
    .current_kwh{ text-align: right; display: inline-block; vertical-align: middle; font-size: 12px; font-weight: 400; color:$text-color; width:80px;}
  }

}
.defult_table .table .MuiDataGrid-row + .MuiDataGrid-row + .MuiDataGrid-row + .MuiDataGrid-row .alert_unread_mark .unread.dnone { background: none !important;}
.defult_select_option{font-size: 12px !important; color: $text-color; line-height: 40px;}



  .defult_form{
    .form_col{ margin-bottom: 20px; position: relative;
      &.date_cal{
        .MuiTextField-root{ margin: 0px; border: 1px solid #ccc; border-radius:5px;
          .MuiInput-underline:before{ border: none !important;}
          .MuiIconButton-root{ color: $theme-secondary;}
        }
      }
      .error_msg{ position: absolute; color: $alert-marker; font-size: 10px; display: inline-block; vertical-align: middle; left: 155px; top: 42px;}
      &.error{ 
        .MuiOutlinedInput-notchedOutline{ border-color: $alert-marker !important;}
      }
      &.checkbox{ margin-bottom: 10px;
        .MuiFormControlLabel-root{ margin-top: 0px; 
          margin-right: 0px !important;}
        .MuiFormControlLabel-label{ font-size:12px; font-weight: 400; color: $black; padding-left: 3px;}
        .MuiCheckbox-root{ padding: 0px 9px;}
        .MuiCheckbox-root{ color: $theme-secondary;}

      }
      .textarea{
        .MuiFormControl-root{ width: 100%;
          .MuiFormHelperText-contained{ text-align: right; margin-right: 0px; font-size: 10px;}
          .MuiOutlinedInput-multiline{ padding: 10px;
            .MuiOutlinedInput-input{ padding: 0px; height: inherit; max-height: 60px;}
          }
        }
      }
      .MuiSelect-select{ background: url(./assets/images/icons/ic_select_caret.svg) no-repeat 95% center; height: 40px; padding: 0px 10px;}
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{border-color: #ccc;}
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-color: #ccc; border-width: 1px;}
      .MuiSelect-selectMenu{ font-size: 12px; color: $text-color; line-height: 40px;}
      
      .MuiSelect-icon{ display: none;}
  
      .MuiFormControl-root{ width: 100%;
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{ border-width: 1px;}
        .MuiOutlinedInput-notchedOutline{ border-color: #ccc;}
        .MuiInputBase-input{ height:40px; padding: 0px 10px; font-size: 12px; color: $text-color;}
        
      }
      .disable{ background-color: $body-bg; cursor: none;}
      label{ font-size: 14px; font-weight:500; color: $black; display: inline-block; margin-top: 8px;
        .sub_label{ font-size: 10px; color: #666666; line-height: 16px; font-weight: 400; display: block;}
      }
      
    }  
    .MuiDivider-root{ margin: 6px 0px 20px 0px;}
    h3{ font-size: 18px; font-weight:500; color: $black; margin-bottom: 10px;}
    h5{ font-size: 14px; font-weight:500; color: $black; margin-bottom: 10px; text-align: left;}
  }
  
  .edit_detail_section{
    .dialog_title{ background: $body-bg; padding: 13px 30px;
    h4{ margin-top: 10px;}
    
    }
    .defult_form .form_col .single_line label{ width: 155px;}
    
    .map_section{ height:190px; width: calc(100% - 155px); overflow: hidden; border-radius: 5px; display: inline-block; vertical-align: top;
    
    }
    .separator{ background:#e5e5e5; height: 1px; margin: 15px 0px 15px 0px;}  
    .MuiDialog-paperWidthSm{ min-width: 850px !important; max-width:850px !important; max-height: 98vh !important;}
    .MuiDialogContent-dividers{ padding: 16px 18px;}
    .section_group{ padding: 0px 13px;}
    .checkbox{ 
      .single_line{
      .MuiFormControlLabel-root{width: calc(100% - 155px) !important; margin-right: 0px;}
    }
    }
    .single_line{
     
      .MuiFormControl-root{ width:calc(100% - 155px) !important;
       .MuiInputBase-root{ width: 100%;}
      }
      .MuiInputBase-root{ width:calc(100% - 155px);}
      .MuiSelect-select{width:100%; padding: 0px 10px;}
      .defult_form .form_col .textarea .MuiFormControl-root{ width: calc(100% - 155px);}
    }
  
    .block_label{
      .error_msg{left: 0px; top:72px}
    }

    .time_schedule{
      .MuiOutlinedInput-root{ width:97px !important; margin-right: 0px;}
      .MuiOutlinedInput-root + .MuiOutlinedInput-root{margin-right:0px; float: right;}
     
    }
  
    &.sm{
      .MuiDialog-paperWidthSm{ min-width: 625px !important; max-width:625px !important; max-height: 98vh !important;}
      .defult_form .form_col .single_line label{ width: 170px;}
      .single_line .MuiInputBase-root{width: calc(100% - 170px);}
      .defult_form .form_col .error_msg{ left: 170px;}
      .single_line .MuiFormControl-root .MuiInputBase-root{ width: 100%;}
      .single_line .MuiFormControl-root{width: calc(100% - 170px) !important;}
    }

    &.md{
      .MuiDialog-paperWidthSm{ min-width: 725px !important; max-width:725px !important; max-height: 98vh !important;}
      h5{ margin-bottom: 40px !important;}
    }
    
    .permission{ padding-top:20px;
      legend{ font-size: 14px; font-weight:500; color: $black; margin-bottom: 20px; }
    }
  
  }

 

/*---------Responsive CSS Start--------------*/
@media screen and (max-width:1350px) {
.station_details .port_card .title_row h2{ font-size: 15px;}
}
@media screen and (max-width:1295px) {
.depot_detail_head{
  .status{ margin-bottom: 10px; min-width: 100px;}
}
.scroll_effect .position_fixed_nav{ top:110px;}
.tab_menu_select{top:-30px}
}
@media screen and (max-width:1280px) {
  .vehicle_routes .select_alpha{ margin-left: 10px;}
  .depot_detail_head{
    .stat_list { margin-top: 10px;}
    .status{ margin-bottom: 10px;  min-width: 100px;}
  }
  .scroll_effect {
    .depot_detail_head{
      .stat_list { margin-top: 0px;}
    }
  }
  
  }
@media screen and (max-width:1260px) {
  .depot_state{
    .state{ margin: 14px 10px 0px 10px;}
  }  
 .vehicle_g_status .recent_alert_list ul li .time_detail{ font-size: 14px;}
   
}

@media screen and (max-width:1210px) {
.scroll_effect .depot_information{ height: inherit }
.scroll_effect .position_fixed_nav{ top:143px;}
.depot_detail_head .stat_list { margin-top: 10px; text-align: left; background: #f5f5f5; float: none;}
.scroll_effect .station_details .position_fixed_nav{ top:105px;}
.vehicle_g_status .recent_alert_list ul li .time_detail{ font-size: 11px;}
.vehicle_g_status .recent_alert_list ul li .vehicle_number{ font-size: 12px;}
}

@media screen and (max-width:1280px) {
.driver_schedule .text_right{ text-align: left !important;}
}

@media screen and (max-width:1160px) {
  .view_stop{ min-width: 100px;}
  .depot_state{
    .state{ margin: 14px 5px 0px 5px;}
  } 

  .information_section .info_detail .tb_info_section .result .label{ width: 100%;}
  .information_section .info_detail .tb_info_section .result .result{ width: 100%;}

  .information_section .info_detail .tb_info_section.hours .result .label{ width:92px;}
  .information_section .info_detail .tb_info_section.hours .result .result{width: calc(100% - 140px);}
  
.dialog_container.dialog_container_center .MuiDialog-paperWidthSm{ min-width: 90%; max-width:90%; max-height: 98vh;}
    
}

@media screen and (max-width:1100px) {
.station_details .port_card .title_row h2{ font-size: 12px;}
.station_details .information_section .info_detail .tb_info_section .row.set_priority .result{ width: 100%;}
.station_details .information_section .info_detail .tb_info_section .row .label{ display: block;}
.station_details .information_section .info_detail .tb_info_section .row .result{ display: block; width: 100%;}
.map_legend{ font-size: 11px;}
.view_stop{ margin-right: 4px;}
.select_location .select_control{ min-width: 200px !important;}
}
@media screen and (max-width:1070px) {
  .depot_state{
    .state{ margin: 14px 5px 0px 5px;
      span{width: 50px;}
    }
  }  
  .dialog_container.dialog_container_center .MuiDialog-paperWidthSm{ min-width: 98%; max-width:98%; max-height: 98vh;}
}

@media screen and (max-width:1060px) {
.fleet_card_inside{ 
  .fleet_title{ font-size: 16px !important; }
}
}
@media screen and (max-width:1024px) {
  .depot_detail_head{
    .status{ margin-left:5px; }
  }
  }

@media screen and (max-width:1052px) {
  .depot_list{
    .stat_list {
      .status{ margin-bottom: 10px; margin-right: 5px;} 
    }
  }
}
@media screen and (max-width:1000px) {
  .fleet_card_inside{ 
    .fleet_title{ font-size: 15px !important; }
  }
  }

@media screen and (max-width:950px) {
.station_details .information_section .info_detail .tb_info_section .row .label{ display: inline-block;}
.station_details .information_section .info_detail .tb_info_section .row .result{ display: inline-block; width: inherit;}
} 
  
  a[href^="http://maps.google.com/maps"]{display:none !important}
  a[href^="https://maps.google.com/maps"]{display:none !important}
    .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display:none;
  }
  .image-gallery-fullscreen-button {display:none}


