@import "../../App.scss";
.recent_alert_list{ position: relative;
    ul{padding: 0px;
      li{ padding: 8px 0px 13px 22px; margin-bottom:5px; display: block; border-bottom: 1px solid #E5E5E5; position: relative;
        span.unread{ width: 10px; height: 10px; background-color:#A70404; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        span.read{ width: 10px; height: 10px; background-color:$theme; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        span.off_duty{ width: 10px; height: 10px; background-color:#888888; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        span.suspended{ width: 10px; height: 10px; background-color:#E96A24; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        .vehicle_number{ font-size: 14px; font-weight: 500; color: $black; }
        .time_detail{font-size: 14px; font-weight: 400; color: $black;}
        .recent_alert_msg{ margin-top: 5px; font-size: 12px; color: $black;}
        &:last-child{ border:none;}
      }
    }
  }


  .shift_group_row {
    .alert{display: inline-block; margin-right: 30px; margin-bottom: 10px;
    span{ margin-right: 5px !important;}
    }
    .port{display: inline-block; margin-bottom: 10px;
      span{ margin-right: 5px !important;}
    }
  
    .depot_bar{
      span{ border-radius:5px !important; margin-left: 1px;
      &:first-child{ margin-left: 0px;}
      }
    }
    
    .depot_state .state{margin: 14px 0px;
      span.onduty{ background: $theme;}
      span.suspended{ background: $driver-suspended;}
      span.driver-unavailable{background:$driver-unavailable-bg}
      span.charging{ background: #FFF1D1 ;}
      span.available{ background: #DDE5E9;}
      span.offline{ background: #DDDDDD;}
      
    }
    span.onduty{ background: $theme;}
    span.suspended{ background: $driver-suspended;}
    span.driver-unavailable{background:$driver-unavailable-bg}
    span.charging{ background: #FFF1D1 ;}
    span.available{ background: #DDE5E9;}
    span.offline{ background: #DDDDDD;}
  }  