@import "../../App.scss";
.msg_page{ width: calc(100% - 200px);
    main{width: 100%; padding-bottom: 10px !important;}
    }
    .messages{
      .open_new_win{ padding: 0px; float: right;}
      .mid_section_left{
        .small_card{ border-radius: 5px 0px 0px 5px !important;}
      }
    
      .mid_section_right{
        .small_card{ border-radius: 0px 5px 5px 0px !important;}
      }
    
      .driver_list_section{
        .card_content{ padding: 0px !important;
          .select_driver{ border-bottom: 1px solid #EEEEEE; padding: 10px 12px;
          .select_control{ height: 40px;
            .MuiInput-input{line-height:25px}
          }
          }
          .send_msg_block{ background:#f5f5f5; border-radius: 5px; margin: 15px 10px; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
            .MuiInputBase-root{  padding: 0px 20px; width: calc(100% - 80px);}
            .send_msg_button{ padding: 0px; box-shadow: none;}
          }
          .driver_list{ padding: 0px 5px; height: calc(100vh - 172px); overflow-y: auto;
            .online{
              .user_img{
                span{background:$theme !important;}
              }
              .MuiListItemText-root .MuiTypography-root{ font-weight: 500;}
            }
            .offline{
              .user_img{
                span{ background:#888 !important;}
              }
            }
            .user_img{ width: 24px; min-width: 24px; height: 24px; border-radius:50px; margin-right: 5px; position: relative;
               img{width: 24px; min-width: 24px; height: 24px; border-radius:50px;}
               span{ background: #888888; border: 1px solid $white; border-radius: 5px; width: 10px; height: 10px; display: inline-block; position: absolute; right: 0px; bottom: 0px;}
            }
            .MuiListItem-button{ margin-bottom: 3px;}
            .MuiListItem-button:hover{ border-radius: 5px; background: $border-color;}
            .MuiListItem-button.active{ border-radius: 5px; background: $border-color;}
            .MuiListItemText-root .MuiTypography-root{ font-size: 14px; line-height:21px; font-weight: 400; color: $black;}
          }
    
          .msg_listing{ height: calc(100vh - 190px); overflow-y: auto;
            .MuiListItem-root{ align-items: flex-start; padding-top: 5px; padding-bottom: 5px;}
            .MuiListItemText-root .MuiTypography-root{ font-size: 16px; line-height: 24px; font-weight: 400 !important; color: $black;}
            .MuiListItem-button:hover{ background: none;}
            .received .MuiListItemText-root{
              .msg_text{background: #DDDDDD; border-radius:0px 15px 15px 15px; padding: 10px 20px 10px 10px; display: inline-block;}
              .msg_time{ font-size: 12px; font-weight: 400; color: #888; line-height:24px; display: block;}
            }
            .blink_bot .MuiListItemText-root{
              .msg_text{background: #99CCC0; border-radius:0px 15px 15px 15px; padding: 10px 20px 10px 10px; display: inline-block;}
              .msg_time{ font-size: 12px; font-weight: 400; color: #888; line-height:24px; display: block;}
            }
    
            .send {text-align: right;
              .MuiListItemText-root{
                .msg_text{background: #A6C38D; border-radius:15px 0px 15px 15px; padding: 10px 10px 10px 20px; display: inline-block;}
                .msg_time{ font-size: 12px; font-weight: 400; color: #888; line-height:24px; display: block;}
              }
            }
            .user_img{ width:32px; min-width: 32px; height:32px; margin-top: 5px; border-radius:50px; margin-right:10px; position: relative;
              img{width:32px; min-width: 32px; height:32px; border-radius:50px;}
              span{ background: #888888; border: 1px solid $white; border-radius: 5px; width: 10px; height: 10px; display: inline-block; position: absolute; right: 0px; bottom: 0px;}
            }
    
            
            
          }
        }
      }
    }