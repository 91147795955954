@import "../../App.scss";

/*-----------Left Sidebar css Start---------*/
.left_sidenavbar{
  
    .sidbar_logo{ padding: 20px; position: relative;
    a{ min-height: 39px; position: relative; display: inline-block; background: #fff;
    img{position: absolute; z-index: 9999;}
    }
    }
    .menu_list{ padding: 0px 15px;
    .menu_list_item{ padding:8px 13px; min-width: 40px; margin-bottom: 2px; border-radius: 5px;
      .img_ic{ display: block;}
      .img_hover{ display: none;} 
      &:hover{ background: $theme;
      .MuiListItemText-root span{color:$white;}
      .img_ic{ display: none;}
      .img_hover{ display: block;}
      .MuiBadge-badge{ background: #E0EED9; color: $black;}
     
      }
      &.active{background: $theme;
        .MuiListItemText-root span{color:$white;}
        .img_ic{ display: none;}
        .img_hover{ display: block;}
        .MuiBadge-badge{ background: #E0EED9; color: $black;}
      }
      .nav_icon{ padding-right: 8px; min-width: 30px;}
      .MuiListItemText-root span{font-size: 12px !important; color:$theme-secondary;  min-width: 80px; opacity: 1;  transition: all 0.1s ease-in-out !important;}
    }
    }
    .MuiDrawer-paperAnchorDockedLeft{ border: none; box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.05);}
  
    .sidebar_open{ opacity: 1; display: inline-block; padding: 0px; position: absolute; right:21px; cursor: pointer; top:25px; transition: all 0.1s ease-in-out !important;}
    .sidebar_close{ display:none; opacity: 0; padding: 0px; position: absolute; right:19px; top:24px; cursor: pointer; transition: all 0.1s ease-in-out !important;}
  }
  
  .drawer_close{ 
    .sidbar_logo a{ opacity: 0;}
    .left_sidenavbar .menu_list .menu_list_item .MuiListItemText-root span{ opacity: 0 !important;   transition: all 0.1s ease-in-out !important;}
    .sidebar_open{display: none; opacity: 0;}
    .sidebar_close{ display: block; opacity: 1;}
  }
  .inside_page .drawer_close .top_header{width: calc(100% - 73px) !important;}
  
  /*-----------Left Sidebar css End---------*/