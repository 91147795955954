@import "../../App.scss";
.vehicle_g_status{
    .vehicle_state_list{ font-size: 12px; font-weight: 400; color: $black;
    ul{ padding: 0px;
      li{ padding: 8px 0px; display: block;
        span{font-size: 12px; font-weight: 400; color: $black; display: block; margin-bottom: 5px;}
       .vehicle_state_length{ height: 10px; max-width: 100%; border: 1px solid #000; background: #ccc; border-radius: 3px;
        &.parked{background: $parked-bg !important; border-color: $parked-border !important;}
        &.completed{background: $complete-bg !important; border-color: $complete-border !important;}
        &.charging{background: $charging-bg !important; border-color: $charging-border !important;}
        &.transit{background: $transit-bg !important; border-color: $transit-border !important;}
        &.offline{background: $offline-bg !important; border-color: $offline-border !important;}
        &.in-service{background: $error-bg !important; border-color: $error-border !important;}
        &.connected{background: $charging-bg !important; border-color: $charging-border !important;}
      }
        }
      }
    }
   
  
  }