@import "../../../App.scss";
.vehicle_route_list{
    .route_list_item{
      .basic_card{ padding: 0px; 
      .MuiCardContent-root{ padding: 0px !important;}
      }
    .route_map { height: inherit;}  
    .route_details{ padding:5px 10px 10px 15px;
      h4{margin: 0px; font-size: 18px; font-weight: 500; margin-bottom: 10px; color: $text-color;}
      .r_d_item{ font-size: 12px; font-weight: 400; color: $gray-text-label; margin-bottom: 5px;
      img{ margin-right: 5px;}
      }
    }  
    }
  
  }
  
  .vehicle_routes{
    .vehicle_search{ display: inline-block;}
    .show_routes{ text-align: right; font-size: 14px; font-weight: 400; color: $gray-text-label;}
    .select_alpha{ max-width: 160px; display: inline-block; vertical-align: top; 
    .select_control{ background: $white; height:40px; width: 160px;
      .MuiSelect-select{ background-position: left center !important; padding-right: 0px !important; padding-left: 24px !important; }
    }
    }
    .button_section{ display: inline-block; vertical-align: top;
    button{ background: #DDE5E9 !important;}
    }
  }

  .destination_section{ position: relative; max-height: 482px; border: 1px solid #ccc; border-radius: $theme-radius;
    .route_map{ height: 480px; max-height: 480px;}
    .collapsed_destinations {
      width: 30px; top: 50%; margin-top: -25px;
      position: absolute;
    .collaps_action_dest { width: 30px; height: 50px; border-radius:0px 5px 5px 0px;
        background: #ffffff; box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);
        left: 220px; margin-top: 0px; border: 1px solid #ccc; border-left: none; cursor: pointer; text-align: center;
        img{ margin-top:18px; margin-left: -5px;}
      }
    } 
    .widthDestMain{
      z-index: 9;
      position: absolute;
      height: 100%;
      .collaps_action{ display: none;}
    }
    .destination_list_block{top:0px; left: 0px; box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1); z-index:9; position: absolute; background:$white; width: 220px; height: 100%; border-radius: 5px 0px 0px 5px;
     .collaps_action{ width: 30px; height: 50px; border-radius:0px 5px 5px 0px; position: absolute; background: $white; box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1); top:50%; left: 220px; margin-top: -25px; border: 1px solid #ccc; border-left: none; cursor: pointer; text-align: center;
      img{ margin-top:18px; margin-left: -5px;}
    } 
    .destination_head{ font-size: 16px; font-weight: 500; color: $black; background: #eee; border-bottom: 1px solid #CCCCCC; padding: 15px; border-radius: 5px 0px 0px 0px;
     img{ vertical-align: middle; margin-top: -2px; margin-right: 10px;}
    }
    .destination_subhead{ background-color: #DDE5E9; padding: 10px 15px; 
      .destination_time{ font-size: 14px; font-weight: 500; color: $theme-secondary;}
      .destination_dis{ font-size: 12px; color: #666;}
    }
    .destination_subhead ~ .destination_list{height: calc(100% - 114px);}
    .destination_list{ padding: 10px 10px; height: calc(100% - 55px); overflow-x: auto; 
      li{ margin-bottom:0px; padding: 15px 6px 15px 6px; cursor: pointer; align-items: flex-start;  border-bottom: 1px solid #ccc;
     &:last-child{ border: none;}
        &:hover{ background-color:#EEF2F4;}
      .title{ font-size: 12px; font-weight: 500; color: $theme-secondary; line-height: 14px;} 
      .address{ font-size: 11px; color: #666666; line-height: 14px;} 
      .dis{ font-size: 9px; color: $black; }
      .des_numb_icon{width: 25px; min-width: 25px; height: 24px; margin-right: 5px; background: url(../../../assets/images/icons/ic_beenhere.svg)  no-repeat left center;
      span{ color: $white; font-size: 12px; font-weight: 500; text-align: center;  width: 20px;}
      }
      .MuiListItemText-root{ margin: 0px; width: calc(100% - 25px);}
      }
      
    }
    }
  }