
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child{
    stroke:#666666; stroke-opacity: 0.5
  }
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke: #666666; stroke-opacity: 0.5
  }
  .recharts-yAxis .recharts-cartesian-axis-tick:first-child text {
     font-size: 0 !important;
   }

   .graph_height .graph_height{outline: 5px solid #ff0000;
    border-radius: 10px;}

    @media screen and (max-width:1920px) {
      .graph_height{ height:85px !important;}
      }

      @media screen and (max-width:1600px) {
        .graph_height{ height:85px !important;}
        }

   @media screen and (max-width:1280px) {
    .graph_height{ height: 70px !important;}
    }
    @media screen and (max-width:1180px) {
      .graph_height{ height: 70px !important;}
      }
   @media screen and (max-width:1070px) {
   .graph_height{ height: 60px !important;}
   }

  .no_line .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child{
    stroke:none !important;
  }
  .no_line .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke:#E5E5E5 !important;
  }
