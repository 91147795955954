@import "../../App.scss";
.top_header{ 
  background: $body-bg !important ; min-height: 80px; box-shadow:none !important; padding: 0px 30px 0px 30px; 

  .header_form_control{ margin-right: 10px;
    .MuiInputBase-root{ width: 160px;}
    .MuiSelect-select{ background:$white url(../../assets/images/icons/ic_select_caret.svg) no-repeat 135px center; border: none; width: 160px; height:28px; line-height: 30px; border-radius: $theme-radius; font-size: 12px; color: $placeholder-text-color; padding: 6px 24px 6px 12px;}
    .MuiInput-underline:before{ border-bottom: none !important;}
    .Mui-focused.MuiInput-underline:after{ border-bottom: none !important;}
    .MuiSelect-icon{ display: none;}
  }
 .header_inner{ padding: 0px; min-height: 80px;
  .logo{ color: $text-color;}
  .top_action_btn{ padding: 0px; margin-left:10px}
  .top_action_btn:last-child{ margin-right:0px;}
  .MuiSvgIcon-root{ color:$theme-secondary;}
}
.search_box{  height: 40px; position: relative; border-radius: $theme-radius; background: $white; color: $placholder-color; 
  input{font-size: 12px; width:0px; font-weight: 400; padding: 0px 5px 0px 10px; height: 40px; margin-left: 30px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
  }
  .searchIcon{ padding-left: 10px; color: $theme-secondary; height: 100%; position: absolute; pointer-events: none; display: flex;  align-items: center; justify-content: center;}
}

.search_box input:focus{ width: 140px;}
.inside_page_head{ display: none;}
}

.top_action_btn{ padding: 0px !important;}
.optionlist{font-size: 14px !important; padding: 6px 12px !important; margin-bottom:4px !important;}
.optionlist:hover{ background: none;}
.MuiInput-underline:after{border-bottom: none !important;}

.header_breadcrum{ display: inline-block; vertical-align: top;  height: 49px; margin-left: 10px; position: absolute; z-index: 9999;
  b{background: url(../../assets/images/icons/breadcrumb_arrow.png) right 0px no-repeat; position: absolute; z-index: 999; z-index: 999; width: 22px; height: 49px; right: 3px; }
.header_breadcrum_inside{ border-radius: 5px 0px 0px 5px; cursor: pointer; line-height:40px; background: $white; padding-left: 25px; height: 40px; margin-right: 22px;padding-right: 25px;
 
li{font-size: 0px; font-weight: 400; color:#91A9B6; 
    a{ cursor: pointer; opacity: 0;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
       transition: all .5s;
  }
.material-icons{ margin-top:-2px; vertical-align: middle; display: none;
   
  }
p{font-size: 12px; font-weight: 400; color: $theme-secondary; cursor: none;}
}
.MuiBreadcrumbs-separator{ display: none; font-size: 0px; width: 8px; height: 12px; background: url(../../assets/images/icons/ic_chevron_right_br.svg) center no-repeat;}

}
.header_breadcrum_inside:hover li a{ width: auto; opacity: 1;}
.header_breadcrum_inside:hover li{ font-size: 12px;}
.header_breadcrum_inside:hover li .material-icons{ display: block;}
.header_breadcrum_inside:hover .MuiBreadcrumbs-separator{ display: block; font-size: 0px;}
} 

/*-----------Inside Page Header css Start---------*/
.inside_page{
  .top_header{ width: calc(100% - 200px) !important; padding-left:30px;
    -webkit-transition: width .5s;
    -moz-transition: width .5s;
    transition: width .5s;
    .header_form_control{ margin-right: 10px;
      .MuiInputBase-root{ width: 160px;}
      .MuiSelect-select{ background:$white url(../../assets/images/icons/ic_select_caret.svg) no-repeat 135px center; border: none; width: 160px; height:28px; line-height: 30px; border-radius: $theme-radius; font-size: 12px; color: $placeholder-text-color; padding: 6px 24px 6px 12px;}
      .MuiInput-underline:before{ border-bottom: none !important;}
      .Mui-focused.MuiInput-underline:after{ border-bottom: none !important;}
      .MuiSelect-icon{ display: none;}
    }
  }
.dashborad_logo{ display: none;}
.inside_page_head{ display: block;}
}
/*-----------Inside Page Header css End---------*/