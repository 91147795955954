@import "../../App.scss";

.table_scroll{ max-width: 100%; 
    .table_footer{ margin-top:20px;
      .btn_white{ margin-right: 10px !important; margin-left: 0px !important;}
      .list_number{ font-size: 14px; color: $black; font-weight: 400;}
      .pagination{height: 40px; border-radius: $theme-radius; background: $white; display: inline-block;  vertical-align: middle; margin-right: 10px;
      span{ border-right: 1px solid #f5f5f5; display: inline-block; height: 40px; min-width: 40px; cursor: pointer; text-align: center; line-height: 44px; font-size: 14px; font-weight: 400; color: $text-color;}
      }
    }
  }
  .defult_table{ 
      .table{ border:none;
        .bold_cell_text{ font-weight: 500;}
    .empty{ font-size: 0px; border: none; width: 30% !important;}
    .MuiDataGrid-row{background: $white;}
    .MuiDataGrid-row:first-child{ border-radius: $theme-radius $theme-radius 0px 0px}
    .MuiDataGrid-row:last-child{ border-radius: 0px 0px $theme-radius $theme-radius;
     .MuiDataGrid-cell{ border-bottom: none !important;}
    }
    .MuiDataGrid-window{ /*background: $white;*/ border-radius: $theme-radius; font-size:12px; font-weight: 400; color:$black;}
    .MuiDataGrid-columnHeaderWrapper{ font-size: 12px; font-weight: 500; color: $black; padding: 0px !important; }
    .MuiDataGrid-columnsContainer{ border: none;}
    .MuiDataGrid-footerContainer{ display: none;}
    .action_columns{ color: $theme-secondary;}
    .MuiDataGrid-iconSeparator{ display: none;}
    .status{ margin-right: 0px; text-align: center; 
    span{ line-height: 28px; display: block;}
    }
    .port_icon{ font-weight: 500; color: $theme-secondary; cursor: pointer;
      img{ margin-right: 14px; vertical-align: middle;}
    }
    }
    .MuiDataGrid-root .MuiDataGrid-row{ cursor: pointer;}
    .MuiDataGrid-root .MuiDataGrid-row:hover{ border-bottom:1px solid #ebebeb ;}
    .MuiDataGrid-root .MuiDataGrid-row:hover{ background: $body-bg;}
    .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus { outline: none;}
    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{ padding: 0;}
  }