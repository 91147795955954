@import "../../App.scss";
.fleet_card{ padding:10px; margin-right: 10px; margin-bottom: 10px; box-shadow: none !important; border-radius: 5px !important; background-color: $white !important;
    
    .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight{ opacity: 0;}
    .fleet_card_inside{ padding: 0px;
      .fleet_title{ font-size: 18px;  font-weight: 500; color: $theme-secondary; padding: 11px 11px 17px 11px; border-bottom:$divider;
        img{ margin-right: 10px; vertical-align: middle; margin-top: -2px; display: inline-block;}
      }
      .detail_list{ padding: 17px 17px 7px 17px;
        ul{ padding: 0px;}
        li{ padding: 0px; font-size: 12px; font-weight: 400; color: $text-color; margin-bottom: 16px;
          .MuiListItemIcon-root{ min-width:20px; margin-right: 10px;}
        }
      }
    }
  }
