@import "../../../App.scss";
    
    .history_charging_list .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child{
    stroke:#65A844; stroke-opacity: 1
  }
  .history_charging_list .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke: #65A844; stroke-opacity: 1
  }
  .recharts-yAxis .recharts-cartesian-axis-tick:first-child text {
     font-size: 0 !important;
   }

   .history_charging_list .graph_height .graph_height{outline: 5px solid #ff0000;
    border-radius: 10px;}
    
  .no_line .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child{
    stroke:none !important;
  }
  .no_line .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke:#E5E5E5 !important;
  }

  /*--------Charge Fee Input Color--------*/
  .dialog_container.set_fee_dialog .list_form .result .form_control .input_text{ color: #666; padding-right: 10px;}
  .dialog_container.set_fee_dialog .list_form .billing_detail_table .result .form_control .input_text{ color: #999; }
  .dialog_container .list_form .result .form_control .input_text .MuiTypography-root{ color: #666 !important;}

  .dialog_container.set_fee_dialog .list_form .billing_detail_table .result .form_control .MuiInputAdornment-positionStart{ margin-right: 2px;}



  
  .dialog_container.set_fee_dialog .list_form .result.dis_text_value .form_control .input_text{color: #999;}

  .dialog_container.set_fee_dialog .list_form .result.dis_ador_value .form_control .input_text{color: #999; }

  .dialog_container.set_fee_dialog .list_form .result.dis_ador_value .form_control .input_text .MuiInputBase-input{text-align: left;}

  .dialog_container.set_fee_dialog .list_form .result.result.dis_ador_value .form_control .MuiInputAdornment-positionStart{ margin-right: 2px;}
  

  /*--------End Charge Fee Input Color--------*/

 #simple-popover .MuiPopover-paper{  background: none !important; box-shadow: none !important;} 

#transitions-popper{ z-index: 99999;
.popover{ background:none !important;}
}



  .edit_event_dialog{
    .error_msg{ font-size: 12px; color: #ff0000; margin: 20px 0px 0px 0px;}
    .dialog_title{ background:#f5f5f5 !important; padding: 13px 30px !important;
      h4{ margin-top: 10px !important;}
    }
    .MuiDialog-paperWidthSm{ background: none; padding-bottom: 10px;   box-shadow: none; min-width: 481px !important; max-width: 481px !important;  background: #fff; min-height: 178px;
     
      .edit_event_inside{  padding:20px 30px 30px 30px;
        h4{ margin: 0px; font-size: 16px; color:#000; margin-bottom: 20px; font-weight: 400;
        span{ font-weight: 500;}
        }
      .edit_evt_row{ width:46%; display: inline-block; padding-right: 25px; vertical-align: top;}
      .edit_evt_row + .edit_evt_row{ text-align: right; padding-right:0px;  width:calc(27% - 10px); display: inline-block; margin-left: 10px; vertical-align: top;}
       
        .select_control{ width:100%}
        .btn_white{ width: 100%; margin: 0px !important;}
        .label{ font-size: 14px; font-weight: 400; margin-bottom: 5px; color: #666; text-align: left;}
        .result{ display: block;  width:100%; 
        &.label_select{display: block;}
        .label_legend{ margin-right: 10px; vertical-align: middle; margin-top: -2px; width: 15px; height: 15px; display: inline-block; border-radius: 3px; background: #ccc; border: 1px solid #333;
        &.peak{ background: #FFDAEB !important; border-color: #FF489C !important;}
        &.off-peak{ background: #CCF1FF !important; border-color: #00B9FF !important;}
        &.super-peak{ background: #f3e0ff !important; border-color:#C365FD !important;}
        }
        }
      }
    }
  }
  

  .dialog_container{
    &.edit_photo_gallery{
      .MuiDialog-paperWidthSm{ min-width: 725px !important; max-width:725px !important; max-height: 98vh !important;}
      .dialog_title{ background: #F5F5F5; padding: 13px 30px;
        h4{ margin-top: 10px;}
     
     }
     .items{ width: 100px;height: 100px;position: relative;display: inline-flex; margin: 24px 7px 12px 6px;
        &:before{content: "DROP";left: 0;top: 0;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.6);position: absolute; border-radius: 6px;-webkit-border-radius: 6px;font-size: 12px; line-height: 18px; text-align: center; padding-top: 38%; color: #FFFFFFCC; transition: all 1s; -webkit-transition: all 1s; display:none; border: 1px dashed #fff; }
        &:hover:before{display: block;}
        img{border-radius: 6px; -webkit-border-radius:6px; width:100%; object-fit: cover;}
        .del{position: absolute;right: -6px;top: -10px;background: $gray-text1;padding: 5px;width: 23px;height: 23px;border-radius: 50%;-webkit-border-radius: 50%;border: 1px solid $white;}
      }
    
       .del-img,button span img{width: 18px !important;}
       .drag{position: absolute;left: 0;bottom: 5px;}
  
       .gallery_photo_upload {
        .MuiPaper-rounded {background-color: $body-bg;border: 1px dashed $available-border; box-shadow: none; }
        .MuiCardContent-root{max-width: 550px;margin: 0 auto; }
        .error {font-size:12px; line-height:24px;font-weight:400;letter-spacing: 0px;border: 1px solid $error-border;background-color: $error-bg;border-radius: 4px;-webkit-border-radius: 4px;padding: 5px 0;text-align: center; }
        .dragdrop .MuiButton-containedPrimary cite{display:block;font-size: 12px;line-height:24px;font-weight:400;letter-spacing: 0px;color: $text-color;font-style:normal;margin: 3px 0 0 0;}
        .up-icon{width: 50px;display: block;margin: 18px auto 5px; }
        .dragdrop {position: relative;
          input[type="file"]{position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;}
          .MuiButton-containedPrimary{display:block;background: none;text-transform: inherit;font-size:18px;line-height:24px;font-weight: 500;letter-spacing: 0px;color: $theme-secondary;
            text-align: center;  box-shadow: none; }
        }
        .drag-text{font-size:12px;line-height:24px;font-weight:400;letter-spacing: 0px;color: $theme-secondary;}
        .drag-text.text-right{text-align: right;}
        .progresbar {margin: 10px  0 0 0;
          label{float: left;color: $text-color;font-size:12px;line-height:24px;
            span{color: $gray-text1;margin: 0 0 0 2px;}
          }
          .range{width: 350px;height: 8px;background: #ccc;border-radius:10px;float: left;margin: 8px 0 0 5px;
            .upper-range{width:230px;height: 100%;background: $complete-bg-dark;border-radius:10px; }
          }
          .cancel-img, .error-img,.cancelred-img{width:18px;margin: -4px 0 0 3px;float: left;}
          .error-img, .cancelred-img  {display: none;}
        }
    }
    .progresbar.upload-error{
      .error-img, .cancelred-img{display: block;}
      .cancel-img{display: none;}
      .range .upper-range{background: #F9A0A0;width: 100%;}
    }
    }}