.vehicle_route_map{ height: 50vh;
  margin-top: 13px; border-radius: 5px; overflow: hidden;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v118/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


.custom-map-control-button {
  // background-color: #fff;
  background: url(../../assets/images/map/current_location.svg) no-repeat center center;
  background-size: cover;
  border: 0;
  border-radius: 2px;
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  height: 41px;
  width: 41px;
  cursor: pointer;
}


.locationIcon{
  height: 18px; width: 18px;
  background: url(../../assets/images/map/current_location.svg) no-repeat center center;
  text-align: center;

}







