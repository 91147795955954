@import "../../App.scss";
.wrapper{ margin-top: 80px;}
.main_container{ padding: 30px 30px 40px 30px !important;}
.fleet_dashboard{
  .dashboard_heading{ font-size: 20px; font-weight: 400; margin: 0px; line-height: 20px; margin-bottom: 10px; color: $black;}
  .fleet_card_list{ max-height:567px; overflow-x: auto;}


}

.MuiCircularProgress-colorPrimary{ position: relative; left: 50%; margin-top:15vh; width: 100px !important; height: 100px !important; color: #25556E !important;}
.inner_progress{ margin-left: -150px; margin-top:25vh;}
