@import "../../App.scss";
.recent_alert_list{ position: relative;
    ul{padding: 0px;
      li{ padding: 8px 0px 13px 22px; margin-bottom:5px; display: block; border-bottom: 1px solid #E5E5E5; position: relative;
        span.unread{ width: 10px; height: 10px; background-color:#A70404; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        span.read{ width: 10px; height: 10px; background-color:$theme; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        span.off_duty{ width: 10px; height: 10px; background-color:#888888; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        span.suspended{ width: 10px; height: 10px; background-color:#E96A24; border-radius:50px; display: inline-block; left: 0px; top: 12px; position: absolute;}
        .vehicle_number{ font-size: 14px; font-weight: 500; color: $black; }
        .time_detail{font-size: 14px; font-weight: 400; color: $black;}
        .recent_alert_msg{ margin-top: 5px; font-size: 12px; color: $black;}
        &:last-child{ border:none;}
      }
    }
  }

  .vehicleSoc{
    color: white;
    height: 4px;
    padding-top:1% !important;
    margin-left: 11px !important;
    max-width: 45px !important;
    font-weight: 500; text-align: center; display: block; line-height: 22px !important;
    border-radius: 10px;
  }
  
  .vehicleSocColor{
    background-color: $theme-secondary;
  }
  
  .vehicleSocColorGrey{
    background-color: $gray-text1;
  }
  
  .vehicleLocationSoc{
    height: 4px !important;
    width: 45px !important;
    border-radius: 10px;
    margin-top: 10px !important;
  }
  
  .vehicleLocationHt {
    height: 4px !important;
  }
  